import React from "react"


import BottomNavigation from "./components/BottomNavigation"
import SettingButton from "./components/SettingButton"

function App(){
    return(
<div>
        <SettingButton />
        <BottomNavigation />
        <h1>hello world</h1>

</div>


    )
}

export default App
